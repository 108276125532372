import { ActionsButton } from 'src/components/UI'
import { useParams } from 'react-router-dom'
import { useContext } from 'react'
import { useDispatch } from 'react-redux'
import { WorkOrdersContext } from './../context'
import Icon from 'src/components/UI/CustomUI/atoms/Icon'
import { Item } from 'src/ducks/types'
import { Action } from 'src/components/UI/CustomUI/molecules/ActionsButton/types'
import { itemsActions } from 'src/ducks/actions'
import { ITEM_STATUS } from 'src/helpers'
import { UpdateBatchPayload } from 'src/ducks/items/types'

const BatchActions = ({ items }: { items: Item[] }) => {
  const { dispatch: dispatchContext, state: { workItems } } = useContext(WorkOrdersContext)

  const dispatch = useDispatch()

  const actions: Action[] = []
  const { id } = useParams()

  const itemsCount = items.length
  const itemsText = `${itemsCount}`
  const itemsIds = items.map((item: Item) => item.itemId)

  const selectedItems = workItems.reduce((acc: any, curr: any) => {
    return (acc = [
      ...acc,
      ...curr.items.filter((item: { checked: any }) => item.checked)
    ])
  }, [])
  const selectedItemsIds = selectedItems.map((item: Item) => item.itemId)

  const UncheckAllWorkItems = () => {
    dispatch({
      type: 'SET_REVIEW_ITEMS', payload: workItems.map((item: any) => {
        item.items.forEach((subitem: any) => {
          subitem.checked = false
          return subitem
        })
        return item
      })
    })
  }

  const UpdateBatch = (payload: UpdateBatchPayload) => {
    dispatchContext({ type: 'SET_ITEMS_LOADING', payload: true })
    dispatch(itemsActions.updateBatch({ ...payload, id: id || '' }, (succ) => {
      dispatchContext({ type: 'SET_ITEMS_LOADING', payload: false })
      if (succ) {
        UncheckAllWorkItems()
        dispatch(itemsActions.fetchItemsByCategory({
          params: { jobId: id, status: ITEM_STATUS.COMPLETE }
        }, (succ, items) => {
          dispatchContext({ type: 'SET_REVIEW_ITEMS', payload: items })
        }))
      }
    }))
  }

  if (itemsCount > 0) {
    actions.push({
      label: `QC Accept ${itemsText} item(s)`,
      icon: <Icon color='lightGray' name='CheckCircle' />,
      onClick: () => {
        UpdateBatch({ itemsIds, partialItem: { status: ITEM_STATUS.ACCEPTED } })
      }
    })
  }

  if (itemsCount > 0) {
    actions.push({
      label: `QC Reject ${itemsText} item(s)`,
      icon: <Icon color='lightGray' name='NotInterested' />,
      onClick: () => {
        dispatchContext({ type: 'SET_SELECTED_ITEMS_IDS', payload: selectedItemsIds })
        dispatchContext({ type: 'SET_MODAL_OPEN', payload: true })
        dispatchContext({ type: 'SET_MODAL_TYPE', payload: 'DELETE_MANY' })
      }
    })
  }

  /* if (itemsCount > 0) {
    actions.push({
      label: `Reset ${itemsText} item(s)`,
      icon: <Icon color='lightGray' name='Restore' />,
      onClick: () => { UpdateBatch({ itemsIds, partialItem: { status: ITEM_STATUS.RESET } }) }
    })
  } */


  return (
    <ActionsButton
      actions={actions}
      variant='outlined'
      text='Actions'
      icon={<Icon name='FormatListBulleted' />}
    />
  )
}

export default BatchActions
