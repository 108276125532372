/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Button, CircularProgress, Grid, Typography } from '@mui/material'
import { FC, useContext, useState } from 'react'
import { useSelector } from 'react-redux'
import Modal from 'src/components/UI/CustomUI/molecules/Modal'
import { getOrdersConflict } from 'src/ducks/selectors'
import { DispatchContext } from '../context'
import { dispatchTypes } from '../context/types'
import styles from './styles.module.scss'

const ProConflictModal: FC<{ onDispatch: () => void, onManualAssign: () => void }> = ({ onDispatch, onManualAssign }) => {
  const { state: { openModal }, dispatch: dispatchContext } = useContext(DispatchContext)
  const [loading, setLoading] = useState(false)

  const ordersConflict = useSelector(getOrdersConflict)

  const handleClose = () => {
    dispatchContext({
      type: dispatchTypes.SET_VALUE,
      payload: { attr: 'openModal', value: false }
    })
  }

  const handleDispatch = () => {
    setLoading(true)
    onDispatch()
  }
  const handleMannualAssign = () => {
    setLoading(true)
    onManualAssign()
  }

  return (
    <Modal
      setOpen={() => { }}
      open={openModal}
      size='lg'
      className={styles.Dispatch__container}
    >
      <Grid>
        <Grid className={styles.Content}>
          <Typography className={styles.Title}>This Pro is already scheduled for a job on this day
          </Typography>
          <Typography className={styles.SubTitle}>
            Are you SURE you want to proceed with booking?
          </Typography>
          <Typography variant='h6' className={styles.Paragraph} >Pro has {ordersConflict?.orders?.length} jobs at this time with a capacity of {ordersConflict?.capacity}.
          </Typography>
        </Grid>
        <Box className={styles.Actions}>
          <Button variant='outlined' onClick={handleClose} className={styles.Action}>
            Cancel
          </Button>
          <Button
            variant='contained'
            onClick={handleDispatch}
            className={styles.Action}
          >
            {loading ? <CircularProgress size="1.3rem" /> : 'Confirm'}
          </Button>
          <Button
            variant='contained'
            onClick={handleMannualAssign}
            className={styles.Action}
          >
            {loading ? <CircularProgress size="1.3rem" /> : 'Manually Assign'}
          </Button>
        </Box>
      </Grid>
    </Modal>
  )
}

export default ProConflictModal