import { FC, useContext, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { Box, Button, CircularProgress, Grid, TextFieldLabel, Typography } from 'src/components/UI'
import Icon from 'src/components/UI/CustomUI/atoms/Icon'
import { itemsActions, vendorActions } from 'src/ducks/actions'
import { getItemById, getJob, getOrderById, getVendor } from 'src/ducks/selectors'
import { ITEM_STATUS } from 'src/helpers'
import { WorkOrdersContext } from '../../../context'

const QCRejectItem: FC = () => {
	const dispatch = useDispatch()
	const { dispatch: dispatchContext, state: { selectedOrderId, selectedItemId } } = useContext(WorkOrdersContext)
	const { id, orderId } = useParams()
	const order = useSelector(getOrderById(selectedOrderId as string))
	const [followupNotes, setFollowupNotescial] = useState('')
	const [buttonLoading, setButtonLoading] = useState(false)
	const item = useSelector(getItemById(selectedItemId as string))
	const vendor = useSelector(getVendor())
	const job = useSelector(getJob())

	useEffect(() => {
		order.vendorId && dispatch(
			vendorActions.fetchVendor(order.vendorId, (_succ: boolean) => {
			})
		)
	}, [dispatch, order.vendorId])

	const handleDelete = () => {
		setButtonLoading(true)
		if (!selectedItemId) {
			setButtonLoading(false)
			return
		}
		dispatch(itemsActions.rejectItem({
			itemId: selectedItemId as string,
			partialItem: { followupNotes, status: ITEM_STATUS.QC_REJECTED },
			id: job?.id || ''
		}, (succ) => {
			handleClose()
			if (succ) {
				dispatch(itemsActions.fetchItemsByCategory({
					params: { jobId: id, orderId: orderId || '' }
				}, (_succ) => {
					setButtonLoading(false)
				}))
			}
		}))
	}

	const handleClose = () => {
		dispatchContext({ type: 'SET_MODAL_OPEN', payload: false })
	}

	return (
		<Grid container spacing={2} padding={2}>

			<Grid container item xs={12}>
				<Grid item xs={12}>
					<Typography variant='h5' align='center'>Provide reason(s) for rejection</Typography>
				</Grid>

			</Grid>
			<Grid item xs={12}>
				<Box>
					<TextFieldLabel
						label=''
						value={followupNotes}
						size='small'
						labelVariant='h6'
						placeholder='Rejecting reason'
						multiline
						rows={6}
						onChange={event => setFollowupNotescial(event.target.value)}
					/>
				</Box>
			</Grid>

			<Grid item container xs={12} spacing={2}>
				<Grid item xs={6}>
					<Button variant='outlined' onClick={handleClose} fullWidth>
						Cancel
					</Button>
				</Grid>
				<Grid item xs={6}>
					<Button
						variant='containedError' disabled={!followupNotes} onClick={handleDelete} fullWidth
						startIcon={!buttonLoading && <Icon name='Save' />}
					>
						{buttonLoading ? <CircularProgress color='info' size='1.4rem' /> : 'Save'}
					</Button>
				</Grid>
			</Grid>
		</Grid>

	)
}

export default QCRejectItem