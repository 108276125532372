import { Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useSelector } from "react-redux";
import { getSiteVisits,getJob  } from "src/ducks/selectors";
import { formatTimestamp, round,formatTimestampTimeZone } from "src/helpers";

const SitevisitsModal = () => {
  const siteVisits = useSelector(getSiteVisits())
  const job = useSelector(getJob())
  const timeZone = job?.properties?.territory?.territoryTimeZone? job.properties.territory.territoryTimeZone : ""

  return (
    <>
      {siteVisits?.map(siteVisit => (
        <Box marginBottom={1.5}>
          <Grid container>
            <Grid container item direction="column" xs={8}>
              <Typography variant="body1Bold">{formatTimestampTimeZone(siteVisit?.scheduledStartTimestamp, 'MM/DD/YYYY HH:mm',timeZone)}</Typography>
              <Typography variant="body1Bold">{siteVisit?.companyName || '-'}</Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="body1Bold" color="var(--green900)">Bid Price: ${round(siteVisit?.bidPrice, 2)}</Typography>
            </Grid>
          </Grid>
          <Typography display="block" marginTop={.5} variant="body1Bold">Site Visit Notes: <Typography display="inline" marginLeft={.2}>{siteVisit?.holdNotes || '-'}</Typography></Typography>
        </Box>
      ))}
    </>
  );
}

export default SitevisitsModal;