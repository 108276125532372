import { ActionsButton } from "src/components/UI"
import { useContext, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Icon from 'src/components/UI/CustomUI/atoms/Icon'
import { Action } from "src/components/UI/CustomUI/molecules/ActionsButton/types"
import theme from "src/components/UI/MaterialUI/theme"
import { capitalize, ITEM_STATUS, ORDER_STATUS } from "src/helpers"
import { itemsActions } from "src/ducks/actions"
import { UpdateItemPayload } from "src/ducks/items/types"
import { getItemsSortedByCategory, getOrderById } from "src/ducks/selectors"
import { WorkOrdersContext } from "../../context"
import { useParams } from "react-router-dom"

const ORDER_STATUS_TO_COMPLETE = [
  ORDER_STATUS.DISPATCHED, ORDER_STATUS.REVIEW, ORDER_STATUS.COMPLETE,
  ORDER_STATUS.INVOICED, ORDER_STATUS.PAID, ORDER_STATUS.IN_PROGRESS,
  ORDER_STATUS.APPROVED
]

const ItemActions = ({ itemId, orderId, status, indexCategory, itemCategory }:
  { itemId: string, orderId: string, status: ITEM_STATUS, indexCategory: number, itemCategory: string }
) => {
  const dispatch = useDispatch()
  const { dispatch: dispatchContext, state: { selectedOrderId } } = useContext(WorkOrdersContext)
  const { id } = useParams()
  const [loading, setLoading] = useState(false)

  const { statusType: orderStatus } = useSelector(getOrderById(orderId))
  const actions: Action[] = []
  const itemsByCategory = useSelector(getItemsSortedByCategory())

  let color = theme.palette.blue[700]
  switch (status) {
    case ITEM_STATUS.ACCEPTED: color = theme.palette.navy[600]; break;
    case ITEM_STATUS.REJECTED: color = theme.palette.red[600]; break;
    case ITEM_STATUS.QC_REJECTED: color = theme.palette.red[600]; break;
    case ITEM_STATUS.PENDING: color = theme.palette.blue[700]; break;
    case ITEM_STATUS.COMPLETE: color = theme.palette.lightBlue[700]; break;
    case ITEM_STATUS.APPROVED: color = theme.palette.green[700]; break;
    case ITEM_STATUS.NEW: color = theme.palette.purple[600]; break;
    default: color = theme.palette.blue[700];
  }

  const UpdateItem = (payload: UpdateItemPayload) => {
    setLoading(true)
    if (selectedOrderId) {
      payload['selectedOrderId'] = selectedOrderId
    }
    dispatch(itemsActions.updateItem(payload, () => {
      dispatch(itemsActions.fetchItemsByCategory({ params: { jobId: id, orderId: selectedOrderId as string } }, (succ) => {
        setLoading(false)
      }))
    }))
    const copyWorkItems = [...itemsByCategory]
    const categoryIndex = copyWorkItems.findIndex((category) => category.category === itemCategory)
    copyWorkItems[categoryIndex].items[indexCategory].checked = !copyWorkItems[categoryIndex].items[indexCategory].checked
    dispatch(itemsActions.setItemsByCategory(copyWorkItems))
  }

  if (status === ITEM_STATUS.APPROVED && orderStatus && ORDER_STATUS_TO_COMPLETE.includes(orderStatus)) {
    actions.push({
      label: `Mark work Complete for item`,
      onClick: () => { UpdateItem({ itemId, partialItem: { status: ITEM_STATUS.COMPLETE } }) }
    })
  }

  if (status === ITEM_STATUS.REJECTED) {
    actions.push({
      label: `Re-add item to Estimate`,
      onClick: () => { UpdateItem({ itemId, partialItem: { status: ITEM_STATUS.NEW } }) }
    })
  }
  if (status === ITEM_STATUS.QC_REJECTED) {
    actions.push({
      label: `Re-add complete item to Estimate`,
      onClick: () => { UpdateItem({ itemId, partialItem: { status: ITEM_STATUS.COMPLETE } }) }
    })
  }

  if (status === ITEM_STATUS.APPROVED) {
    actions.push({
      label: 'Unapprove item',
      onClick: () => { UpdateItem({ itemId, partialItem: { status: ITEM_STATUS.NEW } }) }
    })
  }

  if (status === ITEM_STATUS.NEW) {
    actions.push({
      label: 'Approve on behalf of client',
      onClick: () => { UpdateItem({ itemId, partialItem: { status: ITEM_STATUS.APPROVED } }) }
    })
    actions.push({
      label: 'Reject on behalf of client',
      onClick: () => { UpdateItem({ itemId, partialItem: { status: ITEM_STATUS.REJECTED } }) }
    })
    actions.push({
      label: 'Remove Item',
      onClick: () => {
        dispatchContext({ type: 'SET_SELECTED_ITEM_ID', payload: itemId })
        dispatchContext({ type: 'SET_MODAL_OPEN', payload: true })
        dispatchContext({ type: 'SET_MODAL_TYPE', payload: 'DELETE_ONE' })
      }
    })
  }
  if (status === ITEM_STATUS.COMPLETE) {
    actions.push({
      label: `QC Accept`,
      onClick: () => { UpdateItem({ itemId, partialItem: { status: ITEM_STATUS.ACCEPTED } }) }
    })
    actions.push({
      label: `QC Reject`,
      onClick: () => {
        dispatchContext({ type: 'SET_MODAL_OPEN', payload: true })
        dispatchContext({ type: 'SET_SELECTED_ITEM_ID', payload: itemId })
        dispatchContext({ type: 'SET_MODAL_TYPE', payload: 'QC_REJECT_ITEM' })
      }
    })
    actions.push({
      label: `Reject Estimate line item`,
      onClick: () => {
        dispatchContext({ type: 'SET_MODAL_OPEN', payload: true })
        dispatchContext({ type: 'SET_SELECTED_ITEM_ID', payload: itemId })
        dispatchContext({ type: 'SET_MODAL_TYPE', payload: 'REJECT_ITEM' })
      }
    })
    actions.push({
      label: 'Re-open work order item',
      onClick: () => { UpdateItem({ itemId, partialItem: { status: ITEM_STATUS.APPROVED } }) }
    })
  }

  if (status === ITEM_STATUS.ACCEPTED) {
    actions.push({
      label: `Unaccept work`,
      onClick: () => { UpdateItem({ itemId, partialItem: { status: ITEM_STATUS.COMPLETE } }) }
    })
    /*  actions.push({
       label: ` Re-open work order item`,
       onClick: () => { UpdateItem({ itemId, partialItem: { status: ITEM_STATUS.APPROVED } }) }
     }) */
  }

  return (
    <ActionsButton
      actions={actions}
      variant='contained'
      text={capitalize(status === ITEM_STATUS.QC_REJECTED ? 'QC Rejected' : status)}
      iconPosition='end'
      loading={loading}
      sx={{
        minWidth: '112px !important',
        display: 'flex',
        justifyContent: 'space-evenly',
        backgroundColor: color,
        borderColor: color,
        '&:hover': {
          backgroundColor: color,
          borderColor: color,
        }
      }}
      icon={<Icon name='ArrowDropDown' />}
    />
  )
}

export default ItemActions
