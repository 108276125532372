import { SelectInputOption } from 'src/components/UI/CustomUI/atoms/SelectInput/types'
import { getOptionsFromArray, getOptionsFromArrayMultiple } from 'src/helpers'
import storeInfo from '../store'
import { CompanyItem } from '../types'

export const getCompanies = (): CompanyItem[] => {
  const { companies } = storeInfo.store.getState()
  return companies.items
}

export const getAllCompanies = (): CompanyItem[] => {
  const { companies } = storeInfo.store.getState()
  return companies.allItems
}

export const getTotalCompaniesCount = (): number => {
  const { companies } = storeInfo.store.getState()
  return companies.total
}

export const getCompaniesForDropdown = (): SelectInputOption[] => {
  const { companies } = storeInfo.store.getState()
  const flattenedData = getOptionsFromArray(companies.items, { key: 'affiliateId', label: 'name' }, { key: 'all', label: 'All Affiliates' })
  return flattenedData
}

export const getCompaniesForDropdownWithoutAll = (): SelectInputOption[] => {
  const { companies } = storeInfo.store.getState()
  const flattenedData = getOptionsFromArray(companies.items, { key: 'affiliateId', label: 'name' })
  return flattenedData
}

export const getCompaniesForMultipleField = (): SelectInputOption[] => {
  const { companies } = storeInfo.store.getState()
  const flattenedData = getOptionsFromArrayMultiple(companies.items, { key: 'id', label: 'name' })
  return flattenedData
}

export const getAllCompaniesForMultipleField = (): SelectInputOption[] => {
  const { companies } = storeInfo.store.getState()
  const flattenedData = getOptionsFromArrayMultiple(companies.allItems, { key: 'id', label: 'name' })
  return flattenedData
}

export const getCompaniesMultipleField = (): SelectInputOption[] => {
  const { companies } = storeInfo.store.getState()
  const flattenedData = getOptionsFromArrayMultiple(companies.items, { key: 'affiliateId', label: 'name' }, { key: 'all', label: 'All Affiliates', id: 'ALL_AFF' })
  return flattenedData
}

export const getAllCompaniesForMultipleFieldWithoutAllOption = (): SelectInputOption[] => {
  const { companies } = storeInfo.store.getState()
  const flattenedData = getOptionsFromArrayMultiple(companies.items, { key: 'affiliateId', label: 'name' })
  return flattenedData.sort((a, b) => a.label.localeCompare(b.label))
}

export const getCompanyById = (id: string): string => {
  const { companies } = storeInfo.store.getState()
  return companies.items.find(company => company.affiliateId === id)?.name || ''
}

export const getCompaniesStart = (): number => {
  const { companies } = storeInfo.store.getState()
  return companies.start
}

export const getCompaniesLimit = (): number => {
  const { companies } = storeInfo.store.getState()
  return companies.limit
}
